var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h4", { staticClass: "mb-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.resources.Filters.i18n) || _vm.resources.Filters.name
              ) +
              "\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "vx-row" },
          [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(_vm.resources.StartDate.i18n) ||
                          _vm.resources.StartDate.name
                      ) +
                      "\n        "
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.start_date,
                    callback: function($$v) {
                      _vm.start_date = $$v
                    },
                    expression: "start_date"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndDate.i18n) ||
                        _vm.resources.EndDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.end_date,
                    callback: function($$v) {
                      _vm.end_date = $$v
                    },
                    expression: "end_date"
                  }
                })
              ],
              1
            ),
            _c("vs-button", {
              staticClass: "mt-5",
              attrs: {
                color: "primary",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-refresh-ccw"
              },
              on: {
                click: function($event) {
                  return _vm.cleanDate()
                }
              }
            }),
            _c("div", { staticClass: "vx-col w-full md:w-1/4 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Country.i18n) ||
                          _vm.resources.Country.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.countryOperation,
                      reduce: function(item) {
                        return item.countryCode
                      },
                      label: "countryName"
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Stage.i18n) ||
                        _vm.resources.Stage.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.stageList,
                    reduce: function(x) {
                      return x.id
                    },
                    label: "name",
                    scrollable: ""
                  },
                  model: {
                    value: _vm.stageId,
                    callback: function($$v) {
                      _vm.stageId = $$v
                    },
                    expression: "stageId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.get()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-between items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                    },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                    "\n                -\n                " +
                                    _vm._s(
                                      _vm.affiliationsList.length -
                                        _vm.currentPage *
                                          _vm.paginationPageSize >
                                        0
                                        ? _vm.currentPage *
                                            _vm.paginationPageSize
                                        : _vm.affiliationsList.length
                                    ) +
                                    "\n                of " +
                                    _vm._s(_vm.affiliationsList.length)
                                )
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        20
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        50
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("50")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        100
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("100")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        150
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("150")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                    },
                    [
                      _c("vs-input", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: {
                          placeholder:
                            _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-4 md:mb-0",
                          on: {
                            click: function($event) {
                              return _vm.gridApi.exportDataAsCsv()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.ExportAs.i18n) ||
                                  _vm.resources.ExportAs.name
                              ) +
                              "\n            CVS\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("ag-grid-vue", {
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.affiliationsList,
                  rowSelection: "single",
                  colResizeDefault: "shift",
                  animateRows: true,
                  floatingFilter: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true
                },
                on: { "row-selected": _vm.onRowSelected }
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t(_vm.resources.News.i18n) || _vm.resources.News.i18n,
            active: _vm.popupInfo
          },
          on: {
            "update:active": function($event) {
              _vm.popupInfo = $event
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home.jpg"),
              alt: "graphic",
              width: "550",
              heigth: "500"
            }
          }),
          _vm._v("-->\n    ")
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.GenericLink.i18n) ||
              _vm.resources.GenericLink.name,
            active: _vm.popupLink
          },
          on: {
            "update:active": function($event) {
              _vm.popupLink = $event
            }
          }
        },
        [
          _c("h5", { staticClass: "font-semibold mb-2" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  (
                    _vm.$t(_vm.resources.ImportantInformation.i18n) ||
                    _vm.resources.ImportantInformation.name
                  ).toUpperCase()
                ) +
                ":\n    "
            )
          ]),
          _c("p", { staticClass: "mb-base" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(_vm.alerts.MsgLinkMembership.i18n) ||
                    _vm.alerts.MsgLinkMembership.name
                ) +
                "\n    "
            )
          ]),
          _c(
            "vs-row",
            {
              staticClass: "mb-4",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "3"
                  }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        type: "gradient",
                        color: "primary",
                        "icon-pack": "material-icons",
                        icon: "notifications_active",
                        href:
                          "https://t.me/VisionTravelbot?start=" + _vm.itaCode
                      }
                    },
                    [_vm._v("Telegram")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("h4", { staticClass: "text-primary text-center mb-base" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.alerts.MsgWhatWait.i18n) ||
                    _vm.alerts.MsgWhatWait.name
                )
              )
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-3/4 mb-base" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-link",
                    "icon-no-border": "",
                    readonly: ""
                  },
                  model: {
                    value: _vm.urlMembership,
                    callback: function($$v) {
                      _vm.urlMembership = $$v
                    },
                    expression: "urlMembership"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4 mb-4" },
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.urlMembership,
                        expression: "urlMembership",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error"
                      }
                    ]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Copy.i18n) ||
                          _vm.resources.Copy.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }