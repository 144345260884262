<template>
  <div>
    <vx-card class="mb-4">
      <!-- <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="success"
            icon-pack="material-icons"
            icon="person_add"
            size="large"
            @click="$router.push({ name: 'purchases-membership' })"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="danger"
            type="gradient"
            icon-pack="feather"
            icon="icon-link"
            size="large"
            @click="popupLink = true"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p>
            {{ $t(resources.Membership.i18n) || $t(resources.Membership.name) }}
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <p>
            {{
              $t(resources.GenerateLink.i18n) || $t(resources.GenerateLink.name)
            }}
          </p>
        </vs-col>
      </vs-row> -->
      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label>
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="start_date"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label>{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="end_date"
          ></datepicker>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate()"
        ></vs-button>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="country"
              :clearable="true"
              :options="countryOperation"
              :reduce="(item) => item.countryCode"
              label="countryName"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="(x) => x.id"
            label="name"
            scrollable
          />
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">{{
            $t(resources.Search.i18n) || resources.Search.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    affiliationsList.length - currentPage * paginationPageSize >
                    0
                      ? currentPage * paginationPageSize
                      : affiliationsList.length
                  }}
                  of {{ affiliationsList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="affiliationsList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.News.i18n) || resources.News.i18n"
      :active.sync="popupInfo"
    >
      <img
        src="../../assets/images/home.jpg"
        alt="graphic"
        width="550"
        heigth="500"
      />-->
      <!-- <iframe
        width="560"
        height="350"
        src="https://www.youtube.com/embed/g31vWN9kcAs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>-->
    </vs-popup>
    <vs-popup
      :title="$t(resources.GenericLink.i18n) || resources.GenericLink.name"
      :active.sync="popupLink"
    >
      <h5 class="font-semibold mb-2">
        {{
          (
            $t(resources.ImportantInformation.i18n) ||
            resources.ImportantInformation.name
          ).toUpperCase()
        }}:
      </h5>
      <p class="mb-base">
        {{ $t(alerts.MsgLinkMembership.i18n) || alerts.MsgLinkMembership.name }}
      </p>
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            type="gradient"
            color="primary"
            icon-pack="material-icons"
            icon="notifications_active"
            :href="`https://t.me/VisionTravelbot?start=${itaCode}`"
            >Telegram</vs-button
          >
        </vs-col>
      </vs-row>
      <h4 class="text-primary text-center mb-base">
        <strong>{{
          $t(alerts.MsgWhatWait.i18n) || alerts.MsgWhatWait.name
        }}</strong>
      </h4>
      <div class="vx-row">
        <div class="vx-col w-full md:w-3/4 mb-base">
          <vs-input
            v-model="urlMembership"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-4">
          <vs-button
            v-clipboard:copy="urlMembership"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect,
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      popupInfo: true,
      popupLink: false,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      start_date: new Date(),
      end_date: new Date(),
      country: "",
      countryOperation: [],
      stageId: 23,
      stageList: [],
      urlMembership: `https://ov.visiontravel.net/purchases/autoMembership/${
        JSON.parse(localStorage.getItem("userInfo")).itaCode
      }`,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      affiliationsList: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Generated"),
          field: "isGenerated",
          width: 110,
          cellRenderer: function (event) {
            let object = "";
            if (event.node.data.isGenerated == 1) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          },
        },
        {
          headerName: this.$i18n.t("Signature"),
          field: "signatureState",
          width: 110,
          cellRenderer: function (event) {
            let object = "";
            if (event.node.data.signatureState == 2) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else if (event.node.data.signatureState == 1)
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-warning), 1);">warning</i>';
            else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          },
        },
        {
          headerName: "#",
          field: "purchaseId",
          filter: true,
          width: 110,
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "total",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Payments"),
          field: "totalPayment",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("PaymentsConfirmed"),
          field: "paymentConfirmed",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("NewMember"),
          field: "newMemberCode",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Customer"),
          field: "name",
          filter: true,
          width: 210,
          cellRenderer: function (event) {
            return `${event.node.data.name} ${event.node.data.lastName}`;
          },
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          filter: true,
          width: 130,
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "orderNumber",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryCode",
          filter: true,
          width: 120,
        },
        {
          headerName: this.$i18n.t("InstallmentNumber"),
          field: "installmentNumber",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    this.getCountries();
    this.getStage();
    this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.start_date = null;
      this.end_date = null;
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.countryOperation = [];
          } else {
            this.countryOperation = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async get() {
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/get`,
        data: {
          status: this.stageId,
          itaCode: this.itaCode,
          country: this.country,
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.data.length == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.affiliationsList = [];
          } else {
            this.affiliationsList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onRowSelected(event) {
      this.$router.push({
        name: "purchases-membership",
        params: {
          purchaseId: event.node.data.purchaseId,
        },
      });
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/4`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stageList = [
              {
                id: "",
                name: this.$i18n.t("All"),
              },
            ];
          } else {
            result.data.push({
              id: "",
              name: this.$i18n.t("All"),
            });
            this.stageList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onCopy() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgCopy"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle",
      });
    },

    onError() {
      this.$vs.notify({
        title: this.$i18n.t("Error"),
        text: this.$i18n.t("MsgError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle",
      });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
<style>
.includeIconOnly.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 0.7em;
}

.vs-button.large {
  font-size: 3.25rem;
}

.vs-button.large .vs-button--icon {
  font-size: 3.25rem;
}
</style>
